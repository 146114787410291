import React from 'react'
import HeaderLink from './HeaderLink'

import headerLinks from '../menu/headerLinks.json'

const styles = {
  nav: {
    ul: {
      display: 'flex',
      listStyle: 'none',
      margin: 0,
      padding: 0,

      li: {
        display: 'block',
        marginLeft: 32,
      }
    },
  }
}

const NavDesktop = () => {
  return (
    <nav css={styles.nav}>
      <ul>
        {headerLinks.links.map((link) => {
          if (!link.external) {
            return (
              <li key={link.name}>
                <HeaderLink to={link.to}>{link.name}</HeaderLink>
              </li>
            )
          }

          return (
            <li key={link.name}>
              <a href={link.to}>{link.name}</a>
            </li>
          )
        })}
      </ul>
    </nav>
  )
}

export default NavDesktop
