import React from 'react'
import { Link } from 'gatsby'

const styles = {
  activeLink: {
    color: '#262626',
  }
}

const HeaderLink = ({ children, to }) => {
  return (
    <Link to={to} activeStyle={styles.activeLink}>{children}</Link>
  )
}

export default HeaderLink
