import React from 'react'
import { keyframes } from '@emotion/react'


import HeaderLink from './HeaderLink'
import { MenuContext } from '../context/MenuContext'
import headerLinks from '../menu/headerLinks.json'

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
`

const slideIn = keyframes`
  0% {
    opacity: 0;
    right: -150px;
  }

  75% {
    opacity: 1;
  }

  100% {
    right: 0;
    opacity: 1;
  }
`

const slideOut = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: -270px;
  }
`

const styles = {
  background: {
    background: 'rgba(0, 0, 0, .6)',
    position: 'fixed',
    height: '100%',
    top: 0,
    left: 0,
    width: '100%',
    visibility: 'hidden',
  },
  backgroundFadeIn: {
    animation: `visibility 0s linear .25s, ${fadeIn} .25s linear`,
    animationIterationCount: 1,
    visibility: 'visible',
  },
  backgroundFadeOut: {
    animation: `${fadeOut} .5s ease-in`,
  },
  menu: {
    background: '#FFF',
    position: 'fixed',
    top: 0,
    height: '100%',
    width: '270px',
    visibility: 'hidden',
  },
  menuSlideIn: {
    animation: `visibility 0s linear .25s, ${slideIn} .25s ease-in`,
    animationIterationCount: 1,
    opacity: 1,
    right: 0,
    visibility: 'visible',
  },
  nav: {
    marginTop: '100px',
  },
  menuLinks: {
    listStyle: 'none',
    margin: 0,
    padding: 0,

    a: {
      display: 'block',
      fontSize: '15px',
      padding: '16px 64px',
    }
  },
}

const MobileMenu = () => {
  return (
    <MenuContext.Consumer>
      {({ isVisible, toggleMenu }) => (
        <>
          <div css={[styles.background, isVisible ? styles.backgroundFadeIn : styles.backgroundFadeOut]} onClick={() => toggleMenu(false)}></div>
          <div css={[styles.menu, isVisible && styles.menuSlideIn]}>
            <nav css={styles.nav}>
              <ul css={styles.menuLinks}>
                {headerLinks.links.map((link) => {
                  if (!link.external) {
                    return (
                      <li key={link.name}>
                        <HeaderLink to={link.to}>{link.name}</HeaderLink>
                      </li>
                    )
                  }

                  return (
                    <li key={link.name}>
                      <a href={link.to}>{link.name}</a>
                    </li>
                  )
                })}
              </ul>
            </nav>
          </div>
        </>
      )}
    </MenuContext.Consumer>
  )
}

export default MobileMenu
