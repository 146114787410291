import React from 'react'

import { MenuContext } from '../context/MenuContext'

const styles = {
  hamburger: {
    background: 'transparent',
    border: 0,
    color: '#444',
    cursor: 'pointer',
    fontSize: '24px',
  },
  nav: {
    ul: {
      display: 'flex',
      listStyle: 'none',
      margin: 0,
      padding: 0,

      li: {
        display: 'block',
        marginLeft: 32,
      }
    },
  }
}

const NavMobile = () => {
  return (
    <MenuContext.Consumer>
      {({ isVisible, toggleMenu }) => (
        <nav css={styles.nav}>
          <button onClick={() => toggleMenu(!isVisible)} css={styles.hamburger}>&#9776;</button>
        </nav>
      )}
    </MenuContext.Consumer>
  )
}

export default NavMobile
