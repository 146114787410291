import React, { useState } from 'react'
import { Global } from '@emotion/react'

import MobileMenu from './MobileMenu'
import { MenuContext } from "../context/MenuContext"

const styles = {
  'a': {
    color: '#666',
    fontSize: '15px',
    fontFamily: 'Roboto, sans-serif',
    textDecoration: 'none',
  },
  body: {
    backgroundColor: '#fafafa',
    fontFamily: 'Roboto, sans-serif',
    margin: '0',
    padding: '0',
  },
  html: {
    margin: '0',
    padding: '0',
  }
}

const Layout = ({ children }) => {

  const [isVisible, setIsVisible] = useState(false)

  const toggleMenu = (visibility) => {
    setIsVisible(visibility)
  }

  return (
    <MenuContext.Provider value={{ isVisible, toggleMenu }}>
      <Global styles={styles} />
      {children}
      <MobileMenu />
    </MenuContext.Provider>
  )
}

export default Layout
