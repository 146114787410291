import React from 'react'
import { Link } from 'gatsby'
import { useMediaQuery } from 'react-responsive'

import logo from '../images/logo.png'
import NavDesktop from './NavDesktop'
import NavMobile from './NavMobile'

const styles = {
  root: {
    backgroundColor: '#FFF',
    borderBottom: '1px solid #dbdbd5',
    height: 60,
    position: 'sticky',
    top: 0
  },
  inner: {
    alignItems: 'center',
    display: 'flex',
    height: '100%',
    justifyContent: 'space-between',
    margin: '0 auto',
    maxWidth: 1280,
    padding: '0 16px',
    width: 'calc(100% - 32px)',

    [`@media (min-width: 768px)`]: {
      padding: '0 32px',
      width: 'calc(100% - 64px)',
    }
  },
  homeLink: {
    display: 'block',
  },
  logo: {
    display: 'block',
    height: 30,
  },
}

const Header = () => {
  const isDesktop = useMediaQuery({
    query: '(min-width: 768px)'
  })
  return (
    <header css={styles.root}>
      <div css={styles.inner}>
        <Link to="/" css={styles.homeLink}>
          <img css={styles.logo} src={logo} alt="One Davila" />
        </Link>
        {isDesktop ? <NavDesktop /> : <NavMobile />}
      </div>
    </header>

  )
}

export default Header
